<template>
  <p class="output" v-html="output"></p>
</template>

<script>
export default {
  name: "Output",
  props: {
    output: {
      type: String,
      reuired: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
</style>