<template>
  <p class="command">{{command}}</p>
</template>

<script>
export default {
  name: "Command",
  props: {
    command: {
      type: String,
      reuired: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  animation: animated-text 2s steps(29,end) 1s 1 normal both;
  white-space: nowrap;
  overflow: hidden;
}

/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: 472px;}
}
</style>