<template>
  <div class="home">
    <Console :logs="visibleLogs" @pushNextLog="pushLog" />
  </div>
</template>

<script>
import Console from "@/components/Console.vue";

export default {
  name: "Home",
  components: {
    Console,
  },
  data() {
    return {
      logs: [
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop>",
          command: "npm whoami",
          output: `<a href="https://www.linkedin.com/in/keithbrosch/" target="_blank">Keith Brosch</a> is a software developer from Detroit. He has worked at <a href="http://24g.com/" target="_blank">24G</a> since 2019, where he primarily builds education and training platforms for clients in the automotive industry.<br/>Before that he worked at <a href="https://premiercg.com/" target="_blank">Premier Communications Group</a> where he made WordPress websites for clients across several industries.`,
        },
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop>",
          command: "cd Resume",
          output: ``,
        },
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop\\Resume>",
          command: "dir",
          output: `<ul><li><a href="https://www.24g.com/virtual" target="_blank">VXP</a></li><li><a href="https://www.24g.com/enterprise#comp-kovn55pr" target="_blank">VW Cup</a></li><li><a href="https://knowyourvw.com/" target="_blank">Know Your VW</a></li><li><a href="https://pointcheck.gg/" target="_blank">Pointcheck</a></li><li><a href="https://revere.gg/" target="_blank">Revere</a></li><li>Education.txt</li><li>Skills.txt</li></ul>`,
        },
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop\\Resume>",
          command: "type Education.txt",
          output: `Keith graduated from <a href="https://wayne.edu/" target="_blank">Wayne State University</a> in 2019 with a BS in Computer Science.`,
        },
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop\\Resume>",
          command: "type Skulls.txt",
          output: `The system cannot find the file specified.`,
        },
        {
          // eslint-disable-next-line
          location: "C:\\Users\\keith\\Desktop\\Resume>",
          command: "type Skills.txt",
          output: `JavaScript, TypeScript, Vuejs, Angular, Node.js, Express, SQL Databases, Amazon S3, Git, HTML5, CSS3, SASS, Jest`,
        },
      ],
      visibleLogs: [],
    };
  },
  methods: {
    pushLog() {
      if (this.visibleLogs.length < this.logs.length) {
        this.visibleLogs.push(this.logs[this.visibleLogs.length]);
      }
    },
  },
  created() {
    this.pushLog();
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #000;
}
</style>
