<template>
  <div class="log">
    <div class="commandFlex">
        <span>{{log.location}}</span>
        <Command :command="log.command" />
    </div>
    <Output :class="{showOutput}" :output="log.output" />
    <br/>
    <br/>
  </div>
</template>

<script>
import Command from './Command.vue';
import Output from './Output.vue';

export default {
  name: "Log",
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  components: {
    Command,
    Output,
  },
  data() {
    return {
      showOutput: false,
    };
  },
  created() {
    setTimeout(() => {
      this.showOutput = true;
      this.$emit('pushNextLog');
      console.log('shown output');
    }, 2000);
  }
};
</script>

<style lang="scss" scoped>
.commandFlex {
  display: flex;
  gap: 3px;
  font-size: 10px;
  color: lightgrey;
  @media only screen and (min-width: 800px) {
    font-size: 14px;
  }
}
.output {
  opacity: 0;
  &.showOutput {
    opacity: 1;
  }
}
</style>