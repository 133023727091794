<template>
  <div class="console">
    <Log v-for="(log, index) in logs" :key="index" @pushNextLog="$emit('pushNextLog')" :log="log"/>
  </div>
</template>

<script>
import Log from './Log.vue';

export default {
  name: "Console",
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  components: {
    Log,
  },
};
</script>

<style lang="scss" scoped>
.console {
  background: transparent;
  padding: 30px 0;
  margin:0 auto;
  width: 600px;
  max-width: 90vw;
  color: #fff;
  min-height: calc(100vh - 60px);
  text-align: left;
}
</style>
